<template>
  <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
    <span slot="title">
      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
    </span>
    <template v-for="item in menuInfo.children">
      <template v-if="(!item.roles || item.roles.filter(element => user.role.includes(element)).length > 0)">
        <template v-if="(!item.menuSettings || item.menuSettings.filter(element => user.menuSetting.includes(element)).length > 0)">
          <item
            v-if="!item.children && !item.divider"
            :menu-info="item"
            :styles="styles"
            :key="item.key"
          />
          <sub-menu v-if="item.children" :menu-info="item" :styles="styles" :key="item.key" />
        </template>
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import Item from './item'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SubMenu',
  components: { Item },
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: Object,
    styles: Object,
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
}
</script>
