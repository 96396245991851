export const getMenuData = [
  {
    category: true,
    title: 'Menu',
  },
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },
  // Requisition
  {
    title: 'Product Requisition',
    key: 'products-requisition',
    icon: 'fa fa-wpforms',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'store_in_charge', 'procurement'], // set user roles with access to this route
    children: [
      {
        title: 'Create Product Requisition',
        key: 'create-requisition',
        url: '/create-requisition',
        roles: ['software_admin', 'hub_in_charge'],
        menuSettings: ['PRODUCT_REQUISITION_CREATE'],
      },
      {
        title: 'Product Requisition History',
        key: 'hub-requisition-history',
        url: '/hub/requisition-history',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Pending Requisition',
        key: 'admin-hub-pending-requisition-history',
        url: '/admin/hub/pending-requisition-history',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Previous Requisition',
        key: 'admin-hub-previous-requisition-history',
        url: '/admin/hub/previous-requisition-history',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Reject Requisition',
        key: 'admin-hub-reject-requisition-history',
        url: '/admin/hub/reject-requisition-history',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Pending Requisition',
        key: 'warehouse-hub-pending-requisition-history',
        url: '/warehouse/hub/pending-requisition/history',
        roles: ['store_in_charge'],
      },
      {
        title: 'Previous Requisition',
        key: 'warehouse-hub-previous-requisition-history',
        url: '/warehouse/hub/previous-requisition/history',
        roles: ['store_in_charge'],
      },
      {
        title: 'Reject Requisition',
        key: 'warehouse-hub-reject-requisition-history',
        url: '/warehouse/hub/reject-requisition/history',
        roles: ['store_in_charge'],
      },
      {
        title: 'Requisition(Admin Approved)',
        key: 'procurement-requisition-history',
        url: '/procurement/requisition/list',
        roles: ['procurement'],
      },
    ],
  },
  // Fuel Requisition
  {
    title: 'Fuel Requisition',
    key: 'fuel-requisition',
    icon: 'fa fa-hourglass-end',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Create Requisition',
        key: 'fuel-requisition-create',
        url: '/fuel-requisition/create',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Requisition History',
        key: 'hub-fuel-requisition-history',
        url: '/hub/fuel-requisition/history',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Pending Requisition',
        key: 'admin-hub-pending-fuel-requisition-history',
        url: '/admin/hub/pending-fuel-requisition/history',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Previous Requisition',
        key: 'admin-hub-previous-fuel-requisition-history',
        url: '/admin/hub/previous-fuel-requisition/history',
        roles: ['software_admin', 'head_of_admin'],
      },
    ],
  },
  // Expense
  {
    title: 'Expense',
    key: 'expense',
    icon: 'fa fa-usd',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Create Expense',
        key: 'hub-expense-create',
        url: '/hub/expense/create',
        roles: ['hub_in_charge'],
      },
      {
        title: 'Pending List',
        key: 'hub-pending-list',
        url: '/hub/expense/pending/list',
        roles: ['hub_in_charge'],
      },
      {
        title: 'Previous List',
        key: 'hub-previous-list',
        url: '/hub/expense/previous/list',
        roles: ['hub_in_charge'],
      },
      {
        title: 'Rejected List',
        key: 'hub-rejected-list',
        url: '/hub/expense/rejected/list',
        roles: ['hub_in_charge'],
      },
      {
        title: 'Pending List',
        key: 'admin-pending-list',
        url: '/admin/expense/pending/list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Previous List',
        key: 'admin-previous-list',
        url: '/admin/expense/previous/list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Rejected List',
        key: 'admin-rejected-list',
        url: '/admin/expense/rejected/list',
        roles: ['software_admin', 'head_of_admin'],
      },
    ],
  },
  // Delivery Challan
  {
    title: 'Delivery Challan',
    key: 'delivery-challan',
    icon: 'fa fa-wrench',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'cxo_user'], // set user roles with access to this route
    children: [
      {
        title: 'Challan List',
        key: 'admin-challan-list',
        url: '/admin/delivery-challan/list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Create Challan',
        key: 'sender-challan-create',
        url: '/sender/delivery-challan/create',
        roles: ['software_admin', 'hub_in_charge', 'cxo_user'],
      },
      {
        title: 'Challan List(Delivered)',
        key: 'send-challan-list',
        url: '/sender/delivery-challan/list',
        roles: ['software_admin', 'hub_in_charge', 'cxo_user'],
      },
      {
        title: 'Challan List(Received)',
        key: 'received-challan-list',
        url: '/receiver/delivery-challan/list',
        roles: ['software_admin', 'hub_in_charge', 'cxo_user'],
      },
    ],
  },
  // Room Booking
  {
    title: 'Room Booking',
    key: 'room-booking',
    icon: 'fa fa-handshake-o',
    roles: ['software_admin', 'head_of_admin', 'room_booking_admin'], // set user roles with access to this route
    children: [
      {
        title: 'Pending List',
        key: 'admin-pending-list',
        url: '/admin/room-booking/pending/list',
        roles: ['software_admin', 'head_of_admin', 'room_booking_admin'],
      },
      {
        title: 'Previous List',
        key: 'admin-previous-list',
        url: '/admin/room-booking/previous/list',
        roles: ['software_admin', 'head_of_admin', 'room_booking_admin'],
      },
      {
        title: 'Reject List',
        key: 'admin-reject-list',
        url: '/admin/room-booking/reject/list',
        roles: ['software_admin', 'head_of_admin', 'room_booking_admin'],
      },
    ],
  },
  // Admin Scenario
  {
    title: 'Admin Scenario',
    key: 'admin-scenario-hub',
    icon: 'fa fa-dashboard',
    roles: ['hub_in_charge', 'admin_scenario_department'], // set user roles with access to this route
    children: [
      {
        title: 'Raise Issue',
        key: 'hub-overall-issue',
        roles: ['hub_in_charge', 'admin_scenario_department'],
        url: '/admin-scenario/hub/overall-issue',
      },
      {
        title: 'All Issue',
        key: 'hub-all-issue',
        roles: ['hub_in_charge', 'admin_scenario_department'],
        url: '/admin-scenario/hub/all-issue',
      },
      {
        title: 'Overall Rating',
        key: 'admin-scenario-accessories-hub-overall-rating',
        roles: ['hub_in_charge'],
        url: '/admin-scenario/hub/overall/rating',
      },
      {
        title: 'Create Weekly Rating',
        key: 'admin-scenario-accessories-hub-rating-create',
        roles: ['hub_in_charge'],
        url: '/admin-scenario/hub/rating/create',
      },
    ],
  },
  // Admin Scenario
  {
    title: 'Admin Scenario(Admin Menu)',
    key: 'admin-menu-admin-scenario',
    icon: 'fa fa-play-circle-o',
    roles: ['software_admin', 'head_of_admin', 'admin_scenario_admin'], // set user roles with access to this route
    children: [
      {
        title: 'Admin Overall Scenario',
        key: 'admin-overall-scenario',
        roles: ['software_admin', 'head_of_admin'],
        url: '/admin-scenario/admin/overall/scenario/dashboard',
      },
      {
        title: 'Dashboard',
        key: 'admin-issue-dashboard',
        roles: ['software_admin', 'head_of_admin', 'admin_scenario_admin'],
        url: '/admin-scenario/admin/issue/dashboard',
      },
      {
        title: 'Pending Issue List',
        key: 'admin-all-pending-issue',
        roles: ['software_admin', 'head_of_admin', 'admin_scenario_admin'],
        url: '/admin-scenario/admin/pending/all-issue',
      },
      {
        title: 'Inprogress Issue List',
        key: 'admin-all-inprogress-issue',
        roles: ['software_admin', 'head_of_admin', 'admin_scenario_admin'],
        url: '/admin-scenario/admin/inprogress/all-issue',
      },
      {
        title: 'Previous Issue List',
        key: 'admin-all-previous-issue',
        roles: ['software_admin', 'head_of_admin', 'admin_scenario_admin'],
        url: '/admin-scenario/admin/previous/all-issue',
      },
    ],
  },
  // Accessories User
  {
    title: 'Admin Scenario(Accessory Menu)',
    key: 'accessory-menu-admin-scenario',
    icon: 'fa fa-scissors',
    roles: ['software_admin', 'admin_scenario_user'], // set user roles with access to this route
    children: [
      {
        title: 'Dashboard',
        key: 'accessory-issue-dashboard',
        roles: ['software_admin', 'admin_scenario_user'],
        url: '/admin-scenario/admin/issue/accessory/dashboard',
      },
      {
        title: 'Pending Issue List',
        key: 'accessory-user-all-pending-issue',
        roles: ['software_admin', 'admin_scenario_user'],
        url: '/admin-scenario/accessory-user/pending/all-issue',
      },
      {
        title: 'Inprogress Issue List',
        key: 'accessory-user-all-inprogress-issue',
        roles: ['software_admin', 'admin_scenario_user'],
        url: '/admin-scenario/accessory-user/inprogress/all-issue',
      },
      {
        title: 'Previous Issue List',
        key: 'accessory-user-all-previous-issue',
        roles: ['software_admin', 'admin_scenario_user'],
        url: '/admin-scenario/accessory-user/previous/all-issue',
      },
    ],
  },
  // Staff
  {
    title: 'Staff',
    key: 'staff',
    icon: 'fa fa-users',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'staff_leave_application_approver'], // set user roles with access to this route
    children: [
      {
        title: 'Create',
        key: 'staff-create',
        roles: ['software_admin', 'head_of_admin', 'hub_in_charge'],
        url: '/staff/create',
      },
      {
        title: 'List',
        key: 'admin-staff-list',
        roles: ['software_admin', 'head_of_admin', 'staff_leave_application_approver'],
        url: '/admin/staff/list',
      },
      {
        title: 'Resign List',
        key: 'admin-resign-staff-list',
        roles: ['software_admin', 'head_of_admin', 'staff_leave_application_approver'],
        url: '/admin/resign-staff/list',
      },
      {
        title: 'List',
        key: 'hub-staff-list',
        roles: ['hub_in_charge'],
        url: '/hub/staff/list',
      },
      {
        title: 'Leave Application Create',
        key: 'staff-leave-application',
        roles: ['hub_in_charge', 'staff_leave_application_approver'],
        url: '/hub/staff/leave-application/create',
      },
      {
        title: 'Leave Application List',
        key: 'staff-leave-application-list',
        roles: ['hub_in_charge'],
        url: '/hub/staff/leave-application',
      },
      {
        title: 'Leave Application List',
        key: 'admin-staff-leave-application-list',
        roles: ['software_admin', 'head_of_admin', 'staff_leave_application_approver'],
        url: '/admin/staff/leave-application',
      },
    ],
  },
  // Generator
  {
    title: 'Generator',
    key: 'generator',
    icon: 'fa fa-industry',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'admin_scenario_user'], // set user roles with access to this route
    children: [
      {
        title: 'Create',
        key: 'generator-info-create',
        roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'admin_scenario_user'],
        url: '/generator-info/create',
      },
      {
        title: 'List',
        key: 'generator-info-list',
        roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'admin_scenario_user'],
        url: '/generator-info/list',
      },
    ],
  },
  // Requisition Transfer
  {
    title: 'Manage Product Transfer',
    key: 'products-requisition-transfer',
    icon: 'fa fa-paper-plane',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'hub_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Pending Requisition',
        key: 'hub-pending-requisition-transfer-list',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/warehouse/hub/pending-requisition-transfer',
      },
      {
        title: 'Previous Requisition',
        key: 'hub-previous-requisition-transfer-list',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/warehouse/hub/previous-requisition-transfer',
      },
      {
        title: 'All Transfers',
        key: 'hub-requisition-all-transfer',
        roles: ['software_admin', 'hub_in_charge'],
        url: '/hub/requisition-all-transfer',
      },
      // {
      //   title: 'Previous Transfers',
      //   key: 'hub-requisition-previous-transfer',
      //   roles: ['software_admin', 'hub_in_charge'],
      //   url: '/hub/requisition-previous-transfer',
      // },
    ],
  },
  // Hub requisition items dispute
  {
    title: 'Dispute Requisition Products',
    key: 'products-dispute-list',
    url: '/hub/requisition-product/dispute',
    icon: 'fa fa-trash',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'hub_in_charge'],
  },
  // requisition dispatch
  {
    title: 'Product Requisition Dispatch',
    key: 'product-requisition-dispatch',
    icon: 'fa fa-truck',
    roles: ['software_admin', 'head_of_admin', 'tejgaon_store_admin'], // set user roles with access to this route
    children: [
      {
        title: 'Pending Dispatch',
        key: 'hub-product-requisition-pending-dispatch',
        roles: ['software_admin', 'head_of_admin', 'tejgaon_store_admin'],
        url: '/hub/requisition/pending-dispatch',
      },
      {
        title: 'Previous Dispatch',
        key: 'hub-product-requisition-previous-dispatch',
        roles: ['software_admin', 'head_of_admin', 'tejgaon_store_admin'],
        url: '/hub/requisition/previous-dispatch',
      },
    ],
  },
  // Purchase Request Menu
  {
    title: 'Purchase Request',
    key: 'purchaseRequest',
    icon: 'fa fa-ticket',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'], // set user roles with access to this route
    children: [
      // Store in charge
      {
        title: 'List',
        key: 'purchaseRequest',
        url: '/purchase-request',
        roles: ['store_in_charge', 'software_admin'],
      },
      {
        title: 'Create PR',
        key: 'purchaseRequestCreate',
        url: '/purchase-request/create',
        roles: ['store_in_charge', 'software_admin'],
      },
      {
        title: 'Create PR Date Wise',
        key: 'purchaseRequestDateWiseCreate',
        url: '/purchase-request/dateWise/create',
        roles: ['store_in_charge', 'software_admin'],
      },
      // Admin
      {
        title: 'List',
        key: 'purchaseRequestAdmin',
        url: '/admin/purchase-request',
        roles: ['software_admin', 'head_of_admin'],
      },
      // Procurement
      {
        title: 'List',
        key: 'purchaseRequestProcurement',
        url: '/procurement/purchase-request',
        roles: ['procurement'],
      },
    ],
  },
  // Product Inbound
  {
    title: 'Product Inbound',
    key: 'product-inbound',
    icon: 'fa fa-shopping-cart',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Purchase Request List',
        key: 'product-inbound-list',
        url: '/product/inbound-list',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge'],
      },
      {
        title: 'Report Download',
        key: 'product-inbound-report-download',
        url: '/report/product/inbound/download',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge'],
      },
    ],
  },
  // Product Inbound
  {
    title: 'Manage Free Product',
    key: 'free-product',
    icon: 'fa fa-archive',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'], // set user roles with access to this route
    children: [
      {
        title: 'PR List',
        key: 'free-purchase-request',
        url: '/free/purchase-request',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
      },
      {
        title: 'Hub Consumption History',
        key: 'admin-hub-free-consumption-history',
        url: '/admin/hub/free/consumption-history',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Hub Consumption History',
        key: 'procurement-hub-free-consumption-history',
        url: '/procurement/hub/free/consumption-history',
        roles: ['software_admin', 'procurement'],
      },
    ],
  },
  // Product stock
  {
    title: 'Product Stock',
    key: 'product-stock',
    icon: 'fa fa-database',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'], // set user roles with access to this route
    children: [
      {
        title: 'Central Warehouse Stock',
        key: 'central-warehouse-stock',
        url: '/central-warehouse-stock',
      },
      {
        title: 'Hub Station Stock',
        key: 'admin-hub-station-stock',
        url: '/admin/hub/station-stock',
      },
    ],
  },
  // Hub product stock
  {
    title: 'Product Stock',
    key: 'hub-product-stock',
    url: '/hub/station-stock',
    icon: 'fa fa-database',
    roles: ['hub_in_charge'],
  },
  // Hub product stock and Consumption
  {
    title: 'Free Product Manage',
    key: 'free-product-stock',
    icon: 'fa fa-free-code-camp',
    roles: ['software_admin', 'hub_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Pending Product Transfer',
        key: 'pending-product-transfer',
        url: '/hub/free/pending-product',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Previous Product Transfer',
        key: 'previous-product-transfer',
        url: '/hub/free/previous-product',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Product Stock',
        key: 'hub-free-product-stock',
        url: '/hub/free/product-stock',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Consumption History',
        key: 'hub-free-consumption-history',
        url: '/hub/free/consumption-history',
        roles: ['software_admin', 'hub_in_charge'],
      },
    ],
  },
  // Consumption
  {
    title: 'Consumption',
    key: 'Consumption',
    icon: 'fa fa-check-circle-o',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'store_in_charge', 'procurement'], // set user roles with access to this route
    children: [
      {
        title: 'Product Consumption Create',
        key: 'hub-consumption',
        url: '/hub/consumption',
        roles: ['hub_in_charge'],
      },
      {
        title: 'Product Consumption History',
        key: 'hub-consumption-history',
        url: '/hub/consumption-history',
        roles: ['hub_in_charge'],
      },
      {
        title: 'Fuel Consumption Create',
        key: 'hub-fuel-consumption',
        url: '/hub/fuel-consumption',
        roles: ['software_admin', 'hub_in_charge'],
      },
      {
        title: 'Product Consumption History',
        key: 'warehouse-hub-consumption-history',
        url: '/warehouse/hub/consumption-history',
        roles: ['store_in_charge'],
      },
      {
        title: 'Product Consumption History',
        key: 'admin/hub-consumption-history',
        url: '/admin/hub/consumption-history',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Product Consumption History',
        key: 'procurement/hub-consumption-history',
        url: '/procurement/hub/consumption-history',
        roles: ['software_admin', 'procurement'],
      },
      {
        title: 'Fuel Consumption History',
        key: 'admin-hub-fuel-consumption-history',
        url: '/admin/hub/fuel-consumption-history',
        roles: ['software_admin', 'head_of_admin'],
      },
    ],
  },
  // Settings
  {
    title: 'Settings',
    key: 'settings',
    icon: 'fa fa-cog',
    roles: ['software_admin', 'head_of_admin', 'procurement', 'store_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Category',
        key: 'category-list',
        url: '/category',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Product',
        key: 'product-list',
        url: '/products',
        roles: ['software_admin', 'head_of_admin', 'procurement', 'store_in_charge'],
      },
      {
        title: 'Vendor',
        key: 'vendor-list',
        url: '/vendor',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Warehouse',
        key: 'warehouse-list',
        url: '/warehouse',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Hub Zone',
        key: 'hub-zone-list',
        url: '/hub-zone',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Cleaning Company',
        key: 'cleaning-company',
        url: '/cleaning-company-list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Security Company',
        key: 'security-company',
        url: '/security-company-list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Annual Leave Date',
        key: 'annual-leave-date',
        url: '/annual-leave-date-list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Staff Type',
        key: 'staff-type',
        url: '/staff-type-list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Staff Leave Type',
        key: 'staff-leave-type',
        url: '/staff-leave-type-list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Hub',
        key: 'hub-list',
        url: '/hub',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Department',
        key: 'department-list',
        url: '/department',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Hub Product',
        key: 'hub-product',
        url: '/hub/product',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge'],
      },
      {
        title: 'Fuel Type',
        key: 'fuel-type-list',
        url: '/fuel-type',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Dispute Type',
        key: 'dispute-type-list',
        url: '/dispute-type',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Fuel Purpose Type',
        key: 'fuel-purpose-type-list',
        url: '/fuel-purpose-type',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Menu Setting',
        key: 'menu-setting-list',
        url: '/menu-setting',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Product Rack',
        key: 'product-rack-list',
        url: '/product-rack',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge'],
      },
      {
        title: 'Chart of Accounts',
        key: 'expense-list',
        url: '/expense/list',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Hub Chart of Account',
        key: 'hub-chart-of-account',
        url: '/hub/chart-of-accounts',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Employee',
        key: 'employee',
        url: '/employee',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Room',
        key: 'rooms',
        url: '/rooms',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Additional Facility',
        key: 'additional-facility',
        url: '/additional-facilities',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Admin Scenario Accessories',
        key: 'admin-scenario-accessories',
        url: '/admin-scenario/accessories',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Admin Scenario Sub Accessories',
        key: 'admin-scenario-sub-accessories',
        url: '/admin-scenario/sub-accessories',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Hub Admin(Admin Scenario)',
        key: 'hub-admin-scenario',
        url: '/admin-scenario/admins',
        roles: ['software_admin', 'head_of_admin'],
      },
      {
        title: 'Accessories User(Admin Scenario)',
        key: 'accessories-user-scenario',
        url: '/admin-scenario/accessories-users',
        roles: ['software_admin', 'head_of_admin'],
      },
    ],
  },
  // User Menu
  {
    title: 'User Management',
    key: 'user-management',
    icon: 'fe fe-user-plus',
    roles: ['software_admin', 'head_of_admin'], // set user roles with access to this route
    children: [
      {
        title: 'Users',
        key: 'user',
        url: '/users',
      },
      {
        title: 'Roles',
        key: 'role',
        url: '/roles',
      },
    ],
  },
  // Report Menu
  {
    title: 'Report',
    key: 'report',
    icon: 'fe fe-file',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'store_in_charge', 'procurement'], // set user roles with access to this route
    children: [
      {
        title: 'Hub Inventory',
        key: 'hub-inventory',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/product-stock/hub',
      },
      {
        title: 'Warehouse Inventory',
        key: 'warehouse-inventory',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/product-stock/hq',
      },
      {
        title: 'Purchase Request',
        key: 'purchase-request',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/purchase-request/search',
      },
      {
        title: 'Hub Consumption',
        key: 'hub-consumption-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/hub/consumption/search',
      },
      {
        title: 'Product',
        key: 'product-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/product/search',
      },
      {
        title: 'Hub Product Requisition',
        key: 'hub-requisition-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/hub/individual/requisition/search',
      },
      {
        title: 'Product Requisition',
        key: 'requisition-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/requisition/search',
      },
      {
        title: 'Requisition',
        key: 'hub-requisition-report',
        roles: ['hub_in_charge'],
        url: '/report/hub/requisition/search',
      },
      {
        title: 'Hub Requisition Amount',
        key: 'hub-requisition-report-amount',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/hub/monthly-requisition/search',
      },
      {
        title: 'Hub Monthly Inbound & Consumption',
        key: 'hub-monthly-expense',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/hub/monthly-expense/search',
      },
      {
        title: 'All Hub Monthly Expense',
        key: 'all-hub-monthly-expense',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/all-hub/monthly-expense/search',
      },
      {
        title: 'Warehouse Monthly Transfer',
        key: 'warehouse-monthly-transfer',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/warehouse/monthly/transfer',
      },
      {
        title: 'Hub Monthly Expense Amount',
        key: 'hub-monthly-expense-transaction',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/hub/monthly/expense/search',
      },
      {
        title: 'All Hub Monthly Expense Amount',
        key: 'all-hub-monthly-expense-transaction',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/all-hub/monthly/expense/search',
      },
      {
        title: 'Product Consumption',
        key: 'product-consumption',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/product-consumption/search',
      },
      {
        title: 'Room Booking',
        key: 'room-booking-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge', 'procurement'],
        url: '/report/room-booking/search',
      },
      {
        title: 'Hub Pending Expense',
        key: 'hub-pending-expense-report',
        roles: ['software_admin', 'head_of_admin'],
        url: '/report/admin/hub-pending-expense/search',
      },
      {
        title: 'Hub Acc Pending Expense',
        key: 'hub-acc-pending-expense-report',
        roles: ['software_admin', 'head_of_admin'],
        url: '/report/admin/acc/hub-pending-expense/search',
      },
      {
        title: 'Hub Product Stock',
        key: 'hub-product-stock-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge'],
        url: '/report/hub-product-stock/search',
      },
      {
        title: 'Warehouse Monthly Product Stock',
        key: 'warehouse-monthly-product-stock-report',
        roles: ['software_admin', 'head_of_admin', 'store_in_charge'],
        url: '/report/warehouse/monthly-stock/search',
      },
    ],
  },
  // Import Menu
  {
    title: 'Import',
    key: 'import',
    icon: 'fa fa-upload',
    roles: ['software_admin', 'head_of_admin'], // set user roles with access to this route
    children: [
      {
        title: 'Hub Import',
        key: 'hub-import',
        url: '/import/hub',
      },
      {
        title: 'Product Import',
        key: 'product-import',
        url: '/import/product',
      },
      {
        title: 'User Import',
        key: 'user-import',
        url: '/import/user',
      },
      {
        title: 'Hub Product Import',
        key: 'hub-product-import',
        url: '/import/hub-product',
      },
      {
        title: 'Hub Product Stock Import',
        key: 'hub-product-stock-import',
        url: '/import/hub/product-stock',
      },
      {
        title: 'Warehouse Product Stock Import',
        key: 'warehouse-product-stock-import',
        url: '/import/warehouse/product-stock',
      },
    ],
  },
  // Export Menu
  {
    title: 'Export',
    key: 'export',
    icon: 'fa fa-download',
    roles: ['software_admin', 'head_of_admin', 'store_in_charge'], // set user roles with access to this route
    children: [
      {
        title: 'Monthly Warehouse Status',
        key: 'monthly-warehouse-report-export',
        url: '/export/monthly-warehouse-report',
      },
      {
        title: 'Monthly Hub Status',
        key: 'monthly-hub-report-export',
        url: '/export/monthly-hub-report',
      },
    ],
  },
  // Profile Menu
  {
    title: 'Profile',
    key: '__profile',
    url: '/profile',
    icon: 'fe fe-user',
    roles: ['software_admin', 'head_of_admin', 'hub_in_charge', 'store_in_charge', 'procurement', 'tejgaon_store_admin'],
  },
]
