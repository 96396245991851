import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import RoomBooking from '@/layouts/RoomBooking'
import DisplayRoomBooking from '@/layouts/DisplayRoomBooking'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Dashboard Route
        {
          path: '/dashboard',
          meta: { title: 'Dashboard' },
          component: () => import('./views/dashboard'),
        },
        // Profile Route
        {
          path: '/profile',
          meta: { title: 'Profile' },
          name: 'profile',
          component: () => import('./views/userManagement/profile/index'),
        },
        {
          path: '/password',
          meta: { title: 'Password' },
          name: 'password',
          component: () => import('./views/userManagement/profile/password-update'),
        },
        // Users Route
        {
          path: '/users',
          meta: {
            title: 'Users',
          },
          component: () => import('./views/userManagement/user/list'),
        },
        // Role Route
        {
          path: '/roles',
          meta: {
            title: 'Roles',
          },
          component: () => import('./views/userManagement/role/list'),
        },
        // Category Route
        {
          path: '/category',
          meta: { title: 'Category' },
          component: () => import('./views/category/list'),
        },
        // Product Route
        {
          path: '/products',
          meta: { title: 'Product' },
          component: () => import('./views/product/list'),
        },
        // Warehouse Route
        {
          path: '/warehouse',
          meta: { title: 'Warehouse' },
          component: () => import('./views/warehouse/list'),
        },
        // Fuel type Route
        {
          path: '/fuel-type',
          meta: { title: 'Fuel Type' },
          component: () => import('./views/fuelType/list'),
        },
        // Dispute type Route
        {
          path: '/dispute-type',
          meta: { title: 'Dispute Type' },
          component: () => import('./views/disputeType/list'),
        },
        // Fuel Purpose type Route
        {
          path: '/fuel-purpose-type',
          meta: { title: 'FUel Purpose Type' },
          component: () => import('./views/fuelPurposeType/list'),
        },
        // Menu setting Route
        {
          path: '/menu-setting',
          meta: { title: 'Menu Setting' },
          component: () => import('./views/menuSetting/list'),
        },
        // Product Rak Route
        {
          path: '/product-rack',
          meta: { title: 'Product Rack' },
          component: () => import('./views/rackMap/list'),
        },
        // Hub Zone Route
        {
          path: '/hub-zone',
          meta: { title: 'Hub Zone' },
          component: () => import('./views/hubZone/list'),
        },
        // Cleaning Company
        {
          path: '/cleaning-company-list',
          meta: { title: 'Cleaning Company' },
          component: () => import('./views/cleaningCompany/list'),
        },
        // Security Company
        {
          path: '/security-company-list',
          meta: { title: 'Security Company' },
          component: () => import('./views/securityCompany/list'),
        },
        // Annual Leave Date
        {
          path: '/annual-leave-date-list',
          meta: { title: 'Annual Leave Date Type' },
          component: () => import('./views/annualLeaveDate/list'),
        },
        // Staff Type
        {
          path: '/staff-type-list',
          meta: { title: 'Staff Type' },
          component: () => import('./views/staffType/list'),
        },
        // Staff Leave Type
        {
          path: '/staff-leave-type-list',
          meta: { title: 'Staff Leave Type' },
          component: () => import('./views/staffLeaveType/list'),
        },
        // Staff Leave Application
        {
          path: '/hub/staff/leave-application/create',
          meta: { title: 'Staff Leave Application' },
          component: () => import('./views/staff/leaveApplication/hub/create'),
        },
        {
          path: '/hub/staff/leave-application',
          name: 'hubStaffApplicationList',
          meta: { title: 'Staff Leave Application' },
          component: () => import('./views/staff/leaveApplication/hub/list'),
        },
        {
          path: '/admin/staff/leave-application',
          name: 'adminStaffApplicationList',
          meta: { title: 'Staff Leave Application' },
          component: () => import('./views/staff/leaveApplication/admin/list'),
        },
        // Hub Route
        {
          path: '/hub',
          meta: { title: 'Hub' },
          component: () => import('./views/hub/list'),
        },
        {
          path: '/hub/product',
          name: 'hubProduct',
          meta: { title: 'Hub Product' },
          component: () => import('./views/hub/hub_product'),
        },
        {
          path: '/hub/product-assign/:hub_id',
          name: 'hubProductAssign',
          meta: { title: 'Hub Product' },
          component: () => import('./views/hub/hub_product_assign'),
        },
        {
          path: '/hub/chart-of-accounts',
          name: 'hubChartOfAccount',
          meta: { title: 'Hub Chart of Account' },
          component: () => import('./views/hub/hub_coa'),
        },
        {
          path: '/hub/chart-of-accounts/assign/:hub_id',
          name: 'hubChartOfAccountAssign',
          meta: { title: 'Hub Chart of Account' },
          component: () => import('./views/hub/hub_coa_assign'),
        },
        // Vendor Route
        {
          path: '/vendor',
          meta: { title: 'Vendor' },
          component: () => import('./views/vendor/list'),
        },
        // Purchase product Route
        {
          path: '/purchase-request',
          name: 'purchaseRequest',
          meta: { title: 'Purchase Request' },
          component: () => import('./views/purchaseRequest/list'),
        },
        {
          path: '/purchase-request/create',
          name: 'purchaseRequestCreate',
          meta: { title: 'Purchase Request Create' },
          component: () => import('./views/purchaseRequest/create'),
        },
        {
          path: '/purchase-request/dateWise/create',
          name: 'purchaseRequestDateWiseCreate',
          meta: { title: 'Purchase Request Create' },
          component: () => import('./views/purchaseRequest/create_date_wise'),
        },
        {
          path: '/purchase-request/update/:purchase_request_id',
          name: 'purchaseRequestUpdate',
          meta: { title: 'Purchase Request Update' },
          component: () => import('./views/purchaseRequest/update'),
        },
        {
          path: '/purchase-request/detail/:purchase_request_id',
          name: 'purchaseRequestDetail',
          key: 'purchaseRequestAdmin',
          meta: { title: 'Purchase Request View' },
          component: () => import('./views/purchaseRequest/details'),
        },
        // free product
        {
          path: '/free/purchase-request',
          name: 'freePurchaseRequest',
          meta: { title: 'Purchase Request' },
          component: () => import('./views/freeProduct/purchaseRequest/warehouse/list'),
        },
        {
          path: '/free/purchase-request/detail/:purchase_request_id',
          name: 'freePurchaseRequestDetail',
          meta: { title: 'Purchase Request View' },
          component: () => import('./views/freeProduct/purchaseRequest/warehouse/details'),
        },
        {
          path: '/free/purchase-request/update/:purchase_request_id',
          name: 'freePurchaseRequestUpdate',
          key: 'free-product-list',
          meta: { title: 'Purchase Request' },
          component: () => import('./views/freeProduct/purchaseRequest/warehouse/free_product_add_details'),
        },
        // Purchase product Route, admin
        {
          path: '/admin/purchase-request',
          name: 'adminPurchaseRequest',
          key: 'purchaseRequestAdmin',
          meta: { title: 'Purchase Request' },
          component: () => import('./views/purchaseRequest/admin/admin-list'),
        },
        {
          path: '/admin/purchase-request/update/:purchase_request_id',
          name: 'adminPurchaseRequestUpdate',
          meta: { title: 'Purchase Request Update' },
          component: () => import('./views/purchaseRequest/admin/admin-update'),
        },
        // product Inbound Route
        {
          path: '/product/inbound-create/:purchase_request_id',
          name: 'productInboundCreate',
          meta: { title: 'Purchase Request Inbound' },
          component: () => import('./views/productInbound/create'),
        },
        {
          path: '/product/inbound-list',
          meta: { title: 'Purchase Request Inbound' },
          component: () => import('./views/productInbound/list'),
        },
        {
          path: '/product/inbound-detail/:purchase_request_id',
          name: 'productInboundDetail',
          meta: { title: 'Product Inbound Detail' },
          component: () => import('./views/productInbound/detail'),
        },
        // Product Stock Route
        {
          path: '/central-warehouse-stock',
          meta: { title: 'Central Warehouse Stock' },
          component: () => import('./views/productStock/warehouse/central_warehouse'),
        },
        {
          path: '/admin/hub/station-stock',
          meta: { title: 'Hub Station Stock' },
          component: () => import('./views/productStock/hub/hub'),
        },
        {
          path: '/admin/hub/station-stock/:hub_id',
          name: 'adminHubProductStockDetail',
          meta: { title: 'Hub Station Stock' },
          component: () => import('./views/productStock/hub/hub_stock_admin'),
        },
        {
          path: '/hub/station-stock',
          name: 'hubProductStockDetail',
          meta: { title: 'Hub Station Stock' },
          component: () => import('./views/productStock/hub/hub_stock'),
        },
        // hub requisition route
        {
          path: '/create-requisition',
          name: 'createRequisition',
          meta: { title: 'Create Product Requisition' },
          component: () => import('./views/requisition/hub/create'),
        },
        {
          path: '/hub/update-requisition/:requisition_id',
          name: 'hubUpdateRequisition',
          meta: { title: 'Update Requisition' },
          component: () => import('./views/requisition/hub/update'),
        },
        {
          path: '/hub/view-requisition/:requisition_id',
          name: 'hubViewRequisition',
          meta: { title: ' Requisition' },
          component: () => import('./views/requisition/hub/view'),
        },
        {
          path: '/hub/requisition-history',
          name: 'hubRequisitionHistory',
          meta: { title: 'Requisition History' },
          component: () => import('./views/requisition/hub/list'),
        },
        // admin hub requisition
        {
          path: '/admin/hub/pending-requisition-history',
          name: 'adminHubPendingRequisitionHistory',
          meta: { title: 'Requisition Pending History' },
          component: () => import('./views/requisition/admin/pending_list'),
        },
        {
          path: '/admin/hub/previous-requisition-history',
          name: 'adminHubPreviousRequisitionHistory',
          meta: { title: 'Requisition Previous History' },
          component: () => import('./views/requisition/admin/previous_list'),
        },
        {
          path: '/admin/hub/reject-requisition-history',
          name: 'adminHubRejectRequisitionHistory',
          meta: { title: 'Requisition Reject History' },
          component: () => import('./views/requisition/admin/reject_list'),
        },
        {
          path: '/admin/hub/requisition-view/:requisition_id',
          name: 'adminHubRequisitionView',
          meta: { title: 'Requisition' },
          component: () => import('./views/requisition/admin/view'),
        },
        {
          path: '/admin/hub/requisition/individual-update/:requisition_id',
          name: 'adminHubRequisitionIndividualUpdate',
          meta: { title: 'Requisition Update' },
          component: () => import('./views/requisition/admin/individual_update'),
        },
        {
          path: '/admin/hub/requisition-update/:requisition_id',
          name: 'adminHubRequisitionUpdate',
          meta: { title: 'Requisition' },
          component: () => import('./views/requisition/admin/update'),
        },
        // warehouse hub pending requisition
        {
          path: '/warehouse/hub/pending-requisition/history',
          name: 'warehouseHubPendingRequisitionHistory',
          meta: { title: 'Requisition Pending History' },
          component: () => import('./views/requisition/warehouse/pending_list'),
        },
        // warehouse hub previous requisition
        {
          path: '/warehouse/hub/previous-requisition/history',
          name: 'warehouseHubPreviousRequisitionHistory',
          meta: { title: 'Requisition Previous History' },
          component: () => import('./views/requisition/warehouse/previous_list'),
        },
        // warehouse hub reject requisition
        {
          path: '/warehouse/hub/reject-requisition/history',
          name: 'warehouseHubRejectRequisitionHistory',
          meta: { title: 'Requisition Reject History' },
          component: () => import('./views/requisition/warehouse/reject_list'),
        },
        // reject requisition view
        {
          path: '/hub/requisition/reject/view/:requisition_id',
          name: 'hubRequisitionRejectView',
          meta: { title: 'Requisition Reject View' },
          component: () => import('./views/requisition/reject-view'),
        },
        {
          path: '/warehouse/hub/requisition-view/:requisition_id',
          name: 'warehouseHubRequisitionView',
          meta: { title: 'Requisition' },
          component: () => import('./views/requisition/warehouse/view'),
        },
        {
          path: '/warehouse/hub/requisition-individual/update/:requisition_id',
          name: 'warehouseHubRequisitionIndividualUpdate',
          meta: { title: 'Requisition Update' },
          component: () => import('./views/requisition/warehouse/individual_update'),
        },
        {
          path: '/warehouse/hub/requisition-update/:requisition_id',
          name: 'warehouseHubRequisitionUpdate',
          meta: { title: 'Requisition' },
          component: () => import('./views/requisition/warehouse/update'),
        },
        // hub transfer
        {
          path: '/warehouse/hub/pending-requisition-transfer',
          name: 'hubPendingRequisitionTransfer',
          meta: { title: 'Requisition Transfer' },
          component: () => import('./views/requisition/transfer/warehouse/pending_list'),
        },
        {
          path: '/warehouse/hub/previous-requisition-transfer',
          name: 'hubPreviousRequisitionTransfer',
          meta: { title: 'Requisition Transfer' },
          component: () => import('./views/requisition/transfer/warehouse/previous_list'),
        },
        {
          path: '/warehouse/hub/requisition-transfer-view/:requisition_id',
          name: 'warehouseHubRequisitionTransferView',
          meta: { title: 'Product Requisition Transfer' },
          component: () => import('./views/requisition/transfer/warehouse/transfer_view'),
        },
        {
          path: '/warehouse/hub/requisition-transfer-detail/:requisition_id',
          name: 'warehouseHubRequisitionTransferDetail',
          meta: { title: 'Product Requisition Transfer' },
          component: () => import('./views/requisition/transfer/warehouse/transfer_detail'),
        },
        {
          path: '/hub/requisition-all-transfer',
          name: 'hubRequisitionAllTransfer',
          meta: { title: 'Product Requisition Transfer' },
          component: () => import('./views/requisition/transfer/hub/transfer_list'),
        },
        {
          path: '/hub/requisition-previous-transfer',
          name: 'hubRequisitionPreviousTransfer',
          meta: { title: 'Product Requisition Transfer' },
          component: () => import('./views/requisition/transfer/hub/previous_transfer_list'),
        },
        {
          path: '/hub/requisition/pending-transfer/:requisition_id',
          name: 'hubManageRequisitionPendingTransfer',
          key: 'hub-requisition-all-transfer',
          meta: { title: 'Manage Requisition Transfer' },
          component: () => import('./views/requisition/transfer/hub/transfer_view'),
        },
        {
          path: '/hub/requisition/previous-transfer/:requisition_id',
          name: 'hubManageRequisitionPreviousTransfer',
          meta: { title: 'Manage Requisition Transfer' },
          component: () => import('./views/requisition/transfer/hub/previous_transfer_view'),
        },
        {
          path: '/hub/requisition/pending-dispatch',
          name: 'hubRequisitionPendingDispatch',
          meta: { title: 'Product Requisition Dispatch' },
          component: () => import('./views/requisition/transfer/deliveryFleet/pending-list'),
        },
        {
          path: '/hub/requisition/previous-dispatch',
          name: 'hubRequisitionPreviousDispatch',
          meta: { title: 'Product Requisition Dispatch' },
          component: () => import('./views/requisition/transfer/deliveryFleet/previous-list'),
        },
        {
          path: '/requisition/hub/pending-delivery/list',
          name: 'requisitionHubPendingDeliveryList',
          meta: { title: 'Requisition Pending Delivery' },
          component: () => import('./views/requisition/hub/delivery_pending_list'),
        },
        {
          path: '/requisition/warehouse/pending-approved/list',
          name: 'requisitionWarehousePendingApprovedList',
          meta: { title: 'Requisition Pending Approved' },
          component: () => import('./views/requisition/warehouse/pending_approved_list'),
        },
        {
          path: '/requisition/tejgaon/pending-delivery/list',
          name: 'requisitionTejgaonPendingDeliveryList',
          meta: { title: 'Requisition Pending Delivery' },
          component: () => import('./views/requisition/warehouse/tejgaon_pending_delivery_list'),
        },
        {
          path: '/requisition/warehouse/pending-transfer/list',
          name: 'requisitionWarehousePendingTransferList',
          meta: { title: 'Requisition Pending Transfer' },
          component: () => import('./views/requisition/warehouse/pending_transfer_list'),
        },
        {
          path: '/requisition/admin/pending-approved/list',
          name: 'requisitionAdminPendingApprovedList',
          meta: { title: 'Requisition Pending Approved' },
          component: () => import('./views/requisition/admin/pending_approved_list'),
        },
        // Procurement Requisition Route
        {
          path: '/procurement/requisition/list',
          name: 'procurementRequisitionList',
          meta: { title: 'Requisition' },
          component: () => import('./views/requisition/procurement/list'),
        },
        {
          path: '/procurement/requisition/view/:requisition_id',
          name: 'procurementRequisitionView',
          meta: { title: 'Requisition' },
          component: () => import('./views/requisition/procurement/view'),
        },
        // Procurement Purchase Request Route
        {
          path: '/procurement/purchase-request',
          name: 'procurementPurchaseRequest',
          meta: { title: 'Purchase Request' },
          component: () => import('./views/purchaseRequest/procurement/list'),
        },
        {
          path: '/procurement/purchase-request/view/:purchase_request_id',
          name: 'procurementPurchaseRequestView',
          meta: { title: 'Requisition' },
          component: () => import('./views/purchaseRequest/procurement/details'),
        },
        // Fuel Requisition Route
        {
          path: '/fuel-requisition/create',
          name: 'fuelRequisitionCreate',
          meta: { title: 'Create Fuel Requisition' },
          component: () => import('./views/fuelRequisition/hub/create'),
        },
        {
          path: '/hub/fuel-requisition/history',
          name: 'hubFuelRequisitionHistory',
          meta: { title: 'Requisition History' },
          component: () => import('./views/fuelRequisition/hub/list'),
        },
        {
          path: '/hub/fuel-requisition/view/:fuel_requisition_id',
          name: 'hubFuelRequisitionView',
          meta: { title: 'Fuel Requisition' },
          component: () => import('./views/fuelRequisition/hub/view'),
        },
        {
          path: '/hub/fuel-requisition/update/:fuel_requisition_id',
          name: 'hubFuelRequisitionUpdate',
          meta: { title: 'Update Fuel Requisition' },
          component: () => import('./views/fuelRequisition/hub/update'),
        },
        {
          path: '/admin/hub/fuel-requisition/update/:fuel_requisition_id',
          name: 'adminHubFuelRequisitionUpdate',
          meta: { title: 'Fuel Requisition Update' },
          component: () => import('./views/fuelRequisition/admin/hub/update'),
        },
        {
          path: '/admin/hub/pending-fuel-requisition/history',
          name: 'adminHubFuelPendingRequisitionHistory',
          meta: { title: 'Requisition History Hub' },
          component: () => import('./views/fuelRequisition/admin/hub/list'),
        },
        {
          path: '/admin/hub/previous-fuel-requisition/history',
          name: 'adminHubFuelPreviousRequisitionHistory',
          meta: { title: 'Requisition History Hub' },
          component: () => import('./views/fuelRequisition/admin/hub/previous_list'),
        },
        // // Hub requisition items dispute
        {
          path: '/hub/requisition-product/dispute',
          name: 'hubRequisitionProductDispute',
          meta: { title: 'Hub Requisition Products Dispute' },
          component: () => import('./views/requisitionProductDispute/list'),
        },
        // Report Route
        {
          path: '/report/product-stock/hub',
          name: 'reportProductStockHub',
          meta: { title: 'Hub Inventory' },
          component: () => import('./views/report/inventory/hub'),
        },
        {
          path: '/report/product-stock/hq',
          name: 'reportProductStockHq',
          meta: { title: 'Warehouse Inventory' },
          component: () => import('./views/report/inventory/central_warehouse'),
        },
        {
          path: '/report/purchase-request/search',
          name: 'reportPurchaseRequestSearch',
          meta: { title: 'Purchase Request' },
          component: () => import('./views/report/purchaseRequest/list'),
        },
        {
          path: '/report/hub/consumption/search',
          name: 'reportHubConsumptionSearch',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/report/consumption/hub_date_range'),
        },
        {
          path: '/report/product/search',
          name: 'reportProductSearch',
          meta: { title: 'Product' },
          component: () => import('./views/report/product/list'),
        },
        {
          path: '/report/hub/individual/requisition/search',
          name: 'reportHubIndividualRequisitionSearch',
          meta: { title: 'Requisition' },
          component: () => import('./views/report/requisition/individually-list'),
        },
        {
          path: '/report/hub/monthly-requisition/search',
          name: 'reportHubMonthlyRequisitionSearch',
          meta: { title: 'Requisition' },
          component: () => import('./views/report/requisition/monthly-requisition'),
        },
        {
          path: '/report/hub/monthly-expense/search',
          name: 'reportHubMonthlyExpenseSearch',
          meta: { title: 'Requisition' },
          component: () => import('./views/report/hub/monthly_expense'),
        },
        {
          path: '/report/all-hub/monthly-expense/search',
          name: 'reportAllHubMonthlyExpenseSearch',
          meta: { title: 'Requisition' },
          component: () => import('./views/report/hub/all_hub_monthly_expense'),
        },
        {
          path: '/report/warehouse/monthly/transfer',
          name: 'reportWarehouseMonthlyTransfer',
          meta: { title: 'Requisition Items Monthly Transfer' },
          component: () => import('./views/report/requisition/warehouse_monthly_transfer'),
        },
        {
          path: '/report/hub/monthly/expense/search',
          name: 'reportHubMonthlyExpense',
          meta: { title: 'Hub Monthly Expense' },
          component: () => import('./views/report/expense/hub_month_wise_expense'),
        },
        {
          path: '/report/all-hub/monthly/expense/search',
          name: 'reportAllHubMonthlyExpense',
          meta: { title: 'Hub Monthly Expense' },
          component: () => import('./views/report/expense/all_hub_monthly_expense'),
        },
        {
          path: '/report/requisition/search',
          name: 'reportRequisitionSearch',
          meta: { title: 'Requisition' },
          component: () => import('./views/report/requisition/list'),
        },
        {
          path: '/report/hub/requisition/search',
          name: 'reportHubRequisitionSearch',
          meta: { title: 'Requisition' },
          component: () => import('./views/report/hub/requisition/list'),
        },
        {
          path: '/report/product/inbound/download',
          name: 'reportProductInboundDownload',
          meta: { title: 'Report Download' },
          component: () => import('./views/report/purchaseRequest/inbound'),
        },
        {
          path: '/report/hub-product-stock/search',
          name: 'reportHubProductStockSearch',
          meta: { title: 'Hub Product Stock' },
          component: () => import('./views/report/product/product-stock'),
        },
        {
          path: '/report/warehouse/monthly-stock/search',
          name: 'reportWarehouseMonthlyProductStockSearch',
          meta: { title: 'Warehouse Monthly Product Stock' },
          component: () => import('./views/report/inventory/monthly_inventory'),
        },
        // hub product Consumption Route
        {
          path: '/hub/consumption',
          name: 'hubConsumption',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/consumption/hub/list'),
        },
        {
          path: '/hub/consumption-history',
          name: 'hubConsumptionHistory',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/consumption/hub/consumption_history'),
        },
        {
          path: '/warehouse/hub/consumption-history',
          name: 'warehouseHubConsumptionHistory',
          meta: { title: 'Warehouse Consumption' },
          component: () => import('./views/consumption/warehouse/hub_consumption_history'),
        },
        {
          path: '/admin/hub/consumption-history',
          name: 'adminHubConsumptionHistory',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/consumption/admin/hub_list'),
        },
        {
          path: '/procurement/hub/consumption-history',
          name: 'procurementHubConsumptionHistory',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/consumption/admin/hub_list'),
        },
        {
          path: '/admin/hub/free/consumption-history',
          name: 'adminHubFreeConsumptionHistory',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/freeProduct/consumption/admin/hub_list'),
        },
        {
          path: '/procurement/hub/free/consumption-history',
          name: 'procurementHubFreeConsumptionHistory',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/freeProduct/consumption/admin/hub_list'),
        },
        // hub fuel Consumption Route
        {
          path: '/hub/fuel-consumption',
          name: 'hubFuelConsumption',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/fuelConsumption/hub/list'),
        },
        {
          path: '/admin/hub/fuel-consumption-history',
          name: 'adminHubFuelConsumptionHistory',
          meta: { title: 'Hub Consumption' },
          component: () => import('./views/fuelConsumption/admin/list'),
        },
        // Free product stock
        {
          path: '/hub/free/pending-product',
          name: 'hubFreePendingProduct',
          meta: { title: 'Pending Product Transfer' },
          component: () => import('./views/freeProduct/purchaseRequest/hub/pending_transfer_list'),
        },
        {
          path: '/hub/free/previous-product',
          name: 'hubFreePreviousProduct',
          meta: { title: 'Pending Product Transfer' },
          component: () => import('./views/freeProduct/purchaseRequest/hub/previous_transfer_list'),
        },
        {
          path: '/hub/free/product-stock',
          name: 'hubFreeProductStock',
          meta: { title: 'Product Stock' },
          component: () => import('./views/freeProduct/consumption/hub/list'),
        },
        {
          path: '/hub/free/consumption-history',
          name: 'hubFreeConsumptionStock',
          meta: { title: 'Product Stock' },
          component: () => import('./views/freeProduct/consumption/hub/consumption_history'),
        },
        // import
        {
          path: '/import/hub',
          name: 'importHub',
          meta: { title: 'Hub Import' },
          component: () => import('./views/import/hub/hub-insert'),
        },
        {
          path: '/import/product',
          name: 'importProduct',
          meta: { title: 'Product Import' },
          component: () => import('./views/import/product/product-insert'),
        },
        {
          path: '/import/user',
          name: 'importUser',
          meta: { title: 'User Import' },
          component: () => import('./views/import/user/user-list'),
        },
        {
          path: '/import/hub-product',
          name: 'importHubProduct',
          meta: { title: 'Hub Product Import' },
          component: () => import('./views/import/hub/hub-product-insert'),
        },
        {
          path: '/import/hub/product-stock',
          name: 'importHubProductStock',
          meta: { title: 'Hub Product Stock Import' },
          component: () => import('./views/import/stock/hub-stock'),
        },
        {
          path: '/import/warehouse/product-stock',
          name: 'importWarehouseProductStock',
          meta: { title: 'Warehouse Product Stock Import' },
          component: () => import('./views/import/stock/warehouse-stock'),
        },
        // export
        {
          path: '/export/monthly-warehouse-report',
          name: 'monthlyWarehouseReport',
          meta: { title: 'Report Export' },
          component: () => import('./views/export/monthlyStoreReport/monthly_warehouse_store_report'),
        },
        {
          path: '/export/monthly-hub-report',
          name: 'monthlyHubReport',
          meta: { title: 'Report Export' },
          component: () => import('./views/export/monthlyStoreReport/monthly_hub_store_report'),
        },
        // export
        {
          path: '/admin/expense/pending/list',
          name: 'adminExpensePendingList',
          meta: { title: 'Expense Pending List' },
          component: () => import('./views/expense/admin/pending_list'),
        },
        {
          path: '/admin/expense/previous/list',
          name: 'adminExpensePreviousList',
          meta: { title: 'Expense Pending List' },
          component: () => import('./views/expense/admin/previous_list'),
        },
        {
          path: '/admin/expense/rejected/list',
          name: 'adminExpenseRejectedList',
          meta: { title: 'Expense Rejected List' },
          component: () => import('./views/expense/admin/rejected_list'),
        },
        {
          path: '/admin/expense/view/:requested_transaction_id',
          name: 'adminExpenseView',
          meta: { title: 'Expense View' },
          component: () => import('./views/expense/admin/view'),
        },
        {
          path: '/hub/expense/create',
          name: 'hubExpenseCreate',
          meta: { title: 'Chart of Accounts' },
          component: () => import('./views/expense/hub/create'),
        },
        {
          path: '/hub/expense/pending/list',
          name: 'hubExpensePendingList',
          meta: { title: 'Expense Pending List' },
          component: () => import('./views/expense/hub/pending_list'),
        },
        {
          path: '/hub/expense/previous/list',
          name: 'hubExpensePreviousList',
          meta: { title: 'Expense Previous List' },
          component: () => import('./views/expense/hub/previous_list'),
        },
        {
          path: '/hub/expense/rejected/list',
          name: 'hubExpenseRejectedList',
          meta: { title: 'Expense Rejected List' },
          component: () => import('./views/expense/hub/rejected_list'),
        },
        {
          path: '/hub/expense/view/:requested_transaction_id',
          name: 'hubExpenseView',
          meta: { title: 'Expense View' },
          component: () => import('./views/expense/hub/view'),
        },
        {
          path: '/hub/expense/update/:requested_transaction_id',
          name: 'hubExpenseUpdate',
          meta: { title: 'Expense Update' },
          component: () => import('./views/expense/hub/update'),
        },
        {
          path: '/expense/create',
          name: 'expenseCreate',
          key: 'expense-list',
          meta: { title: 'Expense Create' },
          component: () => import('./views/expense/coa/coaMaster/create'),
        },
        {
          path: '/expense/list',
          name: 'expenseList',
          meta: { title: 'Expense List' },
          component: () => import('./views/expense/coa/coaMaster/list'),
        },
        // Department
        {
          path: '/department',
          name: 'departmentList',
          meta: { title: 'Department List' },
          component: () => import('./views/department/list'),
        },
        // Delivery Challan
        {
          path: '/admin/delivery-challan/list',
          name: 'adminChallanList',
          meta: { title: 'Delivery Challan' },
          component: () => import('./views/deliveryChallan/admin/list'),
        },
        {
          path: '/sender/delivery-challan/create',
          name: 'senderDeliveryChallanCreate',
          meta: { title: 'Delivery Challan' },
          component: () => import('./views/deliveryChallan/hub/create'),
        },
        {
          path: '/sender/delivery-challan/update/:delivery_challan_id',
          name: 'senderDeliveryChallanUpdate',
          meta: { title: 'Delivery Challan' },
          component: () => import('./views/deliveryChallan/hub/update'),
        },
        {
          path: '/sender/delivery-challan/list',
          name: 'senderChallanList',
          meta: { title: 'Delivery Challan(Send)' },
          component: () => import('./views/deliveryChallan/hub/sender'),
        },
        {
          path: '/receiver/delivery-challan/list',
          name: 'receiverChallanList',
          meta: { title: 'Delivery Challan(Received)' },
          component: () => import('./views/deliveryChallan/hub/receiver'),
        },
        {
          path: '/huh/delivery-challan/transfer-view/:delivery_challan_id',
          name: 'hubDeliveryChallanTransferView',
          meta: { title: 'Delivery Challan View' },
          component: () => import('./views/deliveryChallan/hub/transfer_view'),
        },
        {
          path: '/huh/delivery-challan/view/:delivery_challan_id',
          name: 'hubDeliveryChallanView',
          meta: { title: 'Delivery Challan View' },
          component: () => import('./views/deliveryChallan/partial/view'),
        },
        // Employee
        {
          path: '/employee',
          name: 'employee',
          meta: { title: 'Employee' },
          component: () => import('./views/employee/list'),
        },
        // Challan Items
        {
          path: '/challan-item',
          name: 'challanItem',
          meta: { title: 'Challan Item' },
          component: () => import('./views/challanItem/list'),
        },
        // Product Consumption
        {
          path: '/report/product-consumption/search',
          name: 'reportProductConsumptionSearch',
          meta: { title: 'Product Consumption' },
          component: () => import('./views/report/product/product-consumption'),
        },
        // Room
        {
          path: '/rooms',
          name: 'rooms',
          meta: { title: 'Room' },
          component: () => import('./views/roomBooking/admin/room/list'),
        },
        // Additional facility
        {
          path: '/additional-facilities',
          name: 'additional-facility',
          meta: { title: 'Room' },
          component: () => import('./views/roomBooking/admin/additionalFacility/list'),
        },
        // Room Booking
        {
          path: '/admin/room-booking/pending/list',
          name: 'admin-room-booking-pending-list',
          meta: { title: 'Room Booking' },
          component: () => import('./views/roomBooking/admin/roomBooking/pending_list'),
        },
        {
          path: '/admin/room-booking/previous/list',
          name: 'admin-room-booking-previous-list',
          meta: { title: 'Room Booking' },
          component: () => import('./views/roomBooking/admin/roomBooking/previous_list'),
        },
        {
          path: '/admin/room-booking/reject/list',
          name: 'admin-room-booking-reject-list',
          meta: { title: 'Room Booking' },
          component: () => import('./views/roomBooking/admin/roomBooking/reject_list'),
        },
        {
          path: '/admin/room-booking/view/:room_booking_id',
          name: 'adminRoomBookingView',
          meta: { title: 'Room Booking Details' },
          component: () => import('./views/roomBooking/admin/roomBooking/view'),
        },
        {
          path: '/report/room-booking/search',
          name: 'reportRoomBookingSearch',
          meta: { title: 'Room Booking Report' },
          component: () => import('./views/report/roomBooking/room_wise_booking'),
        },
        {
          path: '/report/admin/hub-pending-expense/search',
          name: 'reportAdminHubPendingExpenseSearch',
          meta: { title: 'Hub Pending Expense Report' },
          component: () => import('./views/report/expense/all_hub_pending_monthly_expense'),
        },
        {
          path: '/report/admin/acc/hub-pending-expense/search',
          name: 'reportAdminHubAccPendingExpenseSearch',
          meta: { title: 'Hub Pending Acc Expense Report' },
          component: () => import('./views/report/expense/all_hub_account_head_pending_monthly_expense'),
        },
        // Admin Scenario
        {
          path: '/admin-scenario/accessories',
          name: 'adminScenarioAccessories',
          meta: { title: 'Admin Scenario Accessories' },
          component: () => import('./views/adminScenario/accessories/list'),
        },
        {
          path: '/admin-scenario/sub-accessories',
          name: 'adminScenarioSubAccessories',
          meta: { title: 'Admin Scenario Sub Accessories' },
          component: () => import('./views/adminScenario/subAccessories/list'),
        },
        {
          path: '/admin-scenario/admins',
          name: 'adminScenarioAdmins',
          meta: { title: 'Admin Scenario Admins' },
          component: () => import('./views/adminScenario/admins/list'),
        },
        {
          path: '/admin-scenario/admin-hubs/:user_id',
          name: 'adminScenarioAdminHubs',
          meta: { title: 'Admin Scenario Admin Hubs' },
          component: () => import('./views/adminScenario/admins/hub_assign_to_admin'),
        },
        {
          path: '/admin-scenario/admin-hubs/view/:user_id',
          name: 'adminScenarioAdminHubsView',
          meta: { title: 'Admin Scenario Admin Hubs View' },
          component: () => import('./views/adminScenario/admins/view'),
        },
        {
          path: '/admin-scenario/accessories-users',
          name: 'adminScenarioAccessoriesUsers',
          meta: { title: 'Admin Scenario Users' },
          component: () => import('./views/adminScenario/users/list'),
        },
        {
          path: '/admin-scenario/user-accessories/:user_id',
          name: 'adminScenarioUserAccessories',
          meta: { title: 'Admin Scenario User Accessories' },
          component: () => import('./views/adminScenario/users/accessory_assign_to_user'),
        },
        {
          path: '/admin-scenario/user-accessories/view/:user_id',
          name: 'adminScenarioUserAccessoriesView',
          meta: { title: 'Admin Scenario  User Accessories View' },
          component: () => import('./views/adminScenario/users/view'),
        },
        {
          path: '/admin-scenario/hub/all-issue',
          name: 'hubAllIssue',
          meta: { title: 'Hub All Issue' },
          component: () => import('./views/adminScenario/issue/hub/all_issue_list'),
        },
        {
          path: '/admin-scenario/hub/overall-issue',
          name: 'hub-overall-issue',
          meta: { title: 'Hub Overall Issue' },
          component: () => import('./views/adminScenario/issue/hub/overall_issue_list'),
        },
        {
          path: '/admin-scenario/hub/issue/details/:admin_scenario_issue_id',
          name: 'adminScenarioHubIssueDetails',
          meta: { title: 'Hub Issue Details' },
          component: () => import('./views/adminScenario/issue/hub/issue_details'),
        },
        {
          path: '/admin-scenario/admin/issue/details/:admin_scenario_issue_id',
          name: 'adminScenarioAdminIssueDetails',
          meta: { title: 'Admin Issue Details' },
          component: () => import('./views/adminScenario/issue/admin/issue_details'),
        },
        {
          path: '/admin-scenario/admin/issue/details/:admin_scenario_issue_id',
          name: 'adminAdminScenarioIssueView',
          meta: { title: 'Admin Issue Details' },
          component: () => import('./views/adminScenario/issue/admin/issue_details'),
        },
        {
          path: '/admin-scenario/hub/rating/create',
          name: 'adminAdminScenarioHubRatingCreate',
          meta: { title: 'Create Rating Create' },
          component: () => import('./views/adminScenario/rating/hub/create'),
        },
        {
          path: '/admin-scenario/hub/overall/rating',
          name: 'adminAdminScenarioHubOverallRating',
          meta: { title: 'Overall Rating' },
          component: () => import('./views/adminScenario/rating/hub/index'),
        },
        {
          path: '/admin-scenario/admin/overall/scenario/dashboard',
          name: 'admin-overall-scenario-dashboard',
          meta: { title: 'Admin Overall Issue Dashboard' },
          component: () => import('./views/adminScenario/issue/admin/admin-head-index'),
        },
        {
          path: '/admin-scenario/admin/overall/hub/scenario/:user_id',
          name: 'adminOverallHubScenarioDashboard',
          meta: { title: 'Admin Overall Hub Issue Dashboard' },
          component: () => import('./views/adminScenario/issue/admin/admin-head-hub-index'),
        },
        {
          path: '/admin-scenario/admin/issue/dashboard',
          name: 'adminIssueDashboard',
          meta: { title: 'Admin Issue Dashboard' },
          component: () => import('./views/adminScenario/issue/admin/index'),
        },
        {
          path: '/admin-scenario/issue/hub/details/:hub_id',
          name: 'adminScenarioIssueHubAllDetails',
          meta: { title: 'Hub Details' },
          component: () => import('./views/adminScenario/issue/admin/hub_details'),
        },
        {
          path: '/admin-scenario/admin/issue/accessory/dashboard',
          name: 'admin-issue-accessory-dashboard',
          meta: { title: 'Admin Issue Dashboard' },
          component: () => import('./views/adminScenario/issue/admin/accessoriesUser/index'),
        },
        {
          path: '/admin-scenario/issue/accessory/details/:admin_scenario_accessory_id',
          name: 'adminScenarioIssueAccessoryAllDetails',
          meta: { title: 'Accessory Details' },
          component: () => import('./views/adminScenario/issue/admin/accessoriesUser/accessory_details'),
        },
        {
          path: '/admin-scenario/admin/pending/all-issue',
          name: 'adminPendingAllIssue',
          props: true,
          meta: { title: 'Admin All Issue' },
          component: () => import('./views/adminScenario/issue/admin/pending_issue_list'),
        },
        {
          path: '/admin-scenario/admin/inprogress/all-issue',
          name: 'adminInProgressAllIssue',
          props: true,
          meta: { title: 'Admin All Issue' },
          component: () => import('./views/adminScenario/issue/admin/inprogress_issue_list'),
        },
        {
          path: '/admin-scenario/admin/previous/all-issue',
          name: 'adminPreviousAllIssue',
          props: true,
          meta: { title: 'Admin All Issue' },
          component: () => import('./views/adminScenario/issue/admin/previous_issue_list'),
        },
        {
          path: '/admin-scenario/accessory-user/pending/all-issue',
          name: 'accessory-user-all-pending-issue',
          meta: { title: 'Accessories All Issue' },
          component: () => import('./views/adminScenario/issue/admin/accessoriesUser/pending_issue_list'),
        },
        {
          path: '/admin-scenario/accessory-user/inprogress/all-issue',
          name: 'accessory-user-all-inprogress-issue',
          meta: { title: 'Accessories All Issue' },
          component: () => import('./views/adminScenario/issue/admin/accessoriesUser/inprogress_issue_list'),
        },
        {
          path: '/admin-scenario/accessory-user/previous/all-issue',
          name: 'accessory-user-all-previous-issue',
          meta: { title: 'Accessories All Issue' },
          component: () => import('./views/adminScenario/issue/admin/accessoriesUser/previous_issue_list'),
        },
        {
          path: '/admin-scenario/accessory-user/issue/details/:admin_scenario_issue_id',
          name: 'accessoryUserAdminScenarioIssueView',
          meta: { title: 'Accessory User Issue Details' },
          component: () => import('./views/adminScenario/issue/admin/accessoriesUser/issue_details'),
        },
        // Support staff
        {
          path: '/staff/create',
          name: 'staffCreate',
          meta: { title: 'Staff Create' },
          component: () => import('./views/staff/create'),
        },
        {
          path: '/admin/staff/list',
          name: 'staffAdminList',
          meta: { title: 'Staff List' },
          component: () => import('./views/staff/admin/list'),
        },
        {
          path: '/admin/resign-staff/list',
          name: 'resignStaffAdminList',
          meta: { title: 'Resign Staff List' },
          component: () => import('./views/staff/admin/resign_list.vue'),
        },
        {
          path: '/hub/staff/list',
          name: 'staffHubList',
          meta: { title: 'Staff List' },
          component: () => import('./views/staff/hub/list'),
        },
        {
          path: '/staff/update/:staff_id',
          name: 'staffUpdate',
          meta: { title: 'Staff Update' },
          component: () => import('./views/staff/update'),
        },
        {
          path: '/staff/details/:staff_id',
          name: 'staffDetails',
          meta: { title: 'Staff Details' },
          component: () => import('./views/staff/details'),
        },
        // Generator Info module
        {
          path: '/generator-info/list',
          name: 'generatorInfoList',
          meta: { title: 'Generator Info List' },
          component: () => import('./views/generator/generatorInfo/list'),
        },
        {
          path: '/generator-info/create',
          name: 'generatorInfoCreate',
          meta: { title: 'Generator Info Create' },
          component: () => import('./views/generator/generatorInfo/create'),
        },
        {
          path: '/generator-info/update/:generator_info_id',
          name: 'generatorInfoUpdate',
          meta: { title: 'generator Info Update' },
          component: () => import('./views/generator/generatorInfo/update'),
        },
        {
          path: '/generator-fuel-consumption/list/:generator_info_id',
          name: 'generatorFuelConsumptionList',
          meta: { title: 'generator Fuel Consumption' },
          component: () => import('./views/generator/generatorFuelConsumption/list'),
        },
        {
          path: '/generator-fuel-consumption/report/:generator_info_id',
          name: 'generatorFuelConsumptionReport',
          meta: { title: 'generator Fuel Consumption' },
          component: () => import('./views/generator/generatorFuelConsumption/fuel_consumption_report.vue'),
        },
      ],
    },
    // Room Booking
    {
      path: '/room/b',
      name: 'room-booking',
      redirect: '/room-booking',
      component: RoomBooking,
      children: [
        {
          path: '/room/b',
          meta: { title: 'Room Booking' },
          name: 'roomBooking',
          component: () => import('./views/roomBooking/create'),
        },
        {
          path: '/room/b/c/:room_id',
          meta: { title: 'Room Booking' },
          name: 'roomBookingCreate',
          component: () => import('./views/roomBooking/create-two'),
        },
      ],
    },
    // Display room booking
    {
      path: '/display/room/b',
      name: 'display-room-booking',
      redirect: '/display/room-booking',
      component: DisplayRoomBooking,
      children: [
        {
          path: '/display/room/b',
          meta: { title: 'Room Booking' },
          name: 'displayRoomBookingList',
          component: () => import('./views/roomBooking/admin/displayTab/list'),
        },
        {
          path: '/display/room/b/v/:room_id',
          meta: { title: 'Room Booking' },
          name: 'displayRoomBooking',
          component: () => import('./views/roomBooking/admin/displayTab/display'),
        },
      ],
    },
    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          name: 'authLogin',
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password-request',
          meta: {
            title: 'Forgot Password Request',
          },
          component: () => import('./views/auth/forgot-password/forgot-password-request'),
        },
        {
          path: '/auth/forgot-password/:user_name/:verification_code',
          meta: {
            title: 'Forgot Password',
          },
          name: 'forgotPassword',
          component: () => import('./views/auth/forgot-password/forgot-password'),
        },
        {
          path: '/auth/forgot-password/verification-code/:user_name',
          meta: {
            title: 'Verification Code',
          },
          name: 'verificationCode',
          component: () => import('./views/auth/forgot-password/forgot-password-verification-code'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
